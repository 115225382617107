/**
 * @description Bandeau popin-ie
 */

(() => {
  // VARS

  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');

  const root = document.querySelector('body');

  // FUNCTIONS
  const popinIeBanner = () => {
    const banner = `<div class="popin-ie__shadow">
                      <div class="popin-ie module shadow">
                          <i class="btn-icon icon-ie" data-icon="becareful"></i>
                          <h4>Ce site n’est pas compatible avec Internet Explorer</h4>
                          <div class="popin-ie__content">
                              Pour accéder au site veuillez utiliser l’un des navigateurs suivants :
                          </div>
                          <div class="popin-ie__links">
                          <a href="https://www.google.com/intl/fr_fr/chrome/" class="popin-ie__link chrome"><img src="/work/build/images/chrome.svg" width="30" height="30" alt="Chrome">Chrome</a>
                          <a href="https://www.microsoft.com/fr-fr/edge" class="popin-ie__link edge"><img src="/work/build/images/edge.svg" width="28" height="30" alt="Edge">Edge</a>
                          <a href="https://www.mozilla.org/fr/firefox/new/" class="popin-ie__link firefox"><img src="/work/build/images/firefox.svg" width="30" height="30" alt="Firefox">Firefox</a>
                          <a href="https://support.apple.com/downloads/safari" class="popin-ie__link safari"><img src="/work/build/images/safari.svg" width="30" height="30" alt="Safari">Safari</a>
                          </div>
                      </div>
                    </div>`;

    root.insertAdjacentHTML('beforeend', banner);
  };

  // GO 🚀
  const init = () => {
    if (/MSIE \d|Trident.*rv:/.test(navigator.userAgent)) {
      popinIeBanner();
    }
  };

  init();
})();
